import { render, staticRenderFns } from "./PerformancePlatformsSelect.vue?vue&type=template&id=27e52b55&scoped=true"
import script from "./PerformancePlatformsSelect.vue?vue&type=script&lang=js"
export * from "./PerformancePlatformsSelect.vue?vue&type=script&lang=js"
import style0 from "./PerformancePlatformsSelect.vue?vue&type=style&index=0&id=27e52b55&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e52b55",
  null
  
)

export default component.exports